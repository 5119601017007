const MAP_DATA = {
    key: 'bn0FxBBu0XS0Kp2jLaZK',
    STARTING_POS: {//41.62603037633318, 41.636111378682905
        lat: 41.636111378682905,
        lng: 41.62603037633318
    },
    MAX_BOUNDS: [
        [39.95, 41.026218 ],
        [46.765892, 43.624338]
    ],
    STARTING_ZOOM: 13,
    MIN_ZOOM: 6,
    MAX_ZOOm: 19,
    LOCATIONS: {
        TBILISI: {
            BASE: [44.802148541416166, 41.712472415096535],
            AREA: {
                GLDANI: [44.82274874133814, 41.80047446941932],
                DIDUBE: [44.779321918286804, 41.74694154051602],
                VAKE: [44.75711131504232, 41.71120031003082],
                ISANI: [44.85766395885039, 41.682266393528586],
            }
        },
        BATUMI: {
            BASE: [41.6405, 41.6461],
        },
        POTI: {
            BASE: [41.67476358747753, 42.142782903379086],
        },
        KOBULETI: {
            BASE: [41.778822283677734, 41.82873072054238],
        },
        BAKURIANI: {
            BASE: [43.53289533046075, 41.74905283850104],
        }

    }
}

export default MAP_DATA;