
var agentFullInfoDict = {
  "docsData": {
    "docs": {
      "docsName": "",
      "docs": ""
    }
  }
}

function AddDocumentData(funName = "") {
  return agentFullInfoDict;
}


export default AddDocumentData;